import React from 'react'
import styled from 'styled-components'
import { PageMeta } from 'components/Layout/Page'
import HandSection from './components/HandSection'
import TokenomicsTable from './components/TokenomicsTable'

const StyledText = styled.div`
  padding: 20px 30px 20px 30px;
  background: ${({ theme }) => theme.colors.pink};
  font-weight: 500;
  line-height: 57.6px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 48px;
  }
`

const Home: React.FC = () => {
  return (
    <>
      <PageMeta />

      <HandSection />
      {/* Import Table */}
      <TokenomicsTable />
      <StyledText>
        Follow our socials for updates
        <br /> on the coolest decentralized
        <br /> swappery in town!
      </StyledText>
    </>
  )
}

export default React.memo(Home)
