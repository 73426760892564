import styled from 'styled-components'
import { Table, Td } from '@swappery/uikit'
import { isMobile } from 'react-device-detect'

const Tr = styled.tr`
  height: 50px;
`
const StyledTable = styled(Table)`
  font-family: 'Orator-Std';
  font-weight: 400;
  font-size: 11px;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 20px;
  }
`
const StyledTd = styled(Td)`
  padding: 5px 8px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px 10px;
  }
`

const TokenomicsTable: React.FC = () => {
  if (isMobile)
    return (
      <StyledTable>
        <tbody>
          <Tr>
            <StyledTd width="9%" />
            <StyledTd width="9%" />
            <StyledTd width="9%" />
            <StyledTd width="28%" />
            <StyledTd width="18%" />
            <StyledTd width="9%" />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd />
            <StyledTd />
            <StyledTd variant="second">Total tokens</StyledTd>
            <StyledTd variant="second">Vesting</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="second">Seed Round</StyledTd>
            <StyledTd variant="primary">2%</StyledTd>
            <StyledTd variant="primary">20 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd variant="primary">12 Months</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="second">Private sale</StyledTd>
            <StyledTd variant="primary">10%</StyledTd>
            <StyledTd variant="primary">100 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd variant="primary">10 Months</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="second">Public sale</StyledTd>
            <StyledTd variant="primary">10%</StyledTd>
            <StyledTd variant="primary">100 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd variant="primary">8 Months</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="second">Liquidity</StyledTd>
            <StyledTd variant="primary">5%</StyledTd>
            <StyledTd variant="primary">50 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd variant="primary">Unlocked</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="second">Advisory</StyledTd>
            <StyledTd variant="primary">3%</StyledTd>
            <StyledTd variant="primary">30 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd variant="primary">24 Months</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="second">Farming Rewards</StyledTd>
            <StyledTd variant="primary">70%</StyledTd>
            <StyledTd variant="primary">700 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd variant="primary">--</StyledTd>
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd />
            <StyledTd variant="third">Total</StyledTd>
            <StyledTd variant="third">100%</StyledTd>
            <StyledTd variant="third">1 &apos;000 &apos;000 &apos;000 SWPR</StyledTd>
            <StyledTd />
            <StyledTd />
          </Tr>
          <Tr>
            <StyledTd width="9%" />
            <StyledTd width="9%" />
            <StyledTd width="9%" />
            <StyledTd width="28%" />
            <StyledTd width="18%" />
            <StyledTd width="9%" />
          </Tr>
        </tbody>
      </StyledTable>
    )
  return (
    <StyledTable>
      <tbody>
        <Tr>
          <StyledTd width="9%" />
          <StyledTd width="9%" />
          <StyledTd width="9%" />
          <StyledTd width="9%" />
          <StyledTd width="28%" />
          <StyledTd width="18%" />
          <StyledTd width="9%" />
          <StyledTd width="9%" />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Total tokens</StyledTd>
          <StyledTd variant="second">Vesting</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Seed Round</StyledTd>
          <StyledTd variant="primary">2%</StyledTd>
          <StyledTd variant="primary">20 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd variant="primary">12 Months</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Private sale</StyledTd>
          <StyledTd variant="primary">10%</StyledTd>
          <StyledTd variant="primary">100 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd variant="primary">10 Months</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Public sale</StyledTd>
          <StyledTd variant="primary">10%</StyledTd>
          <StyledTd variant="primary">100 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd variant="primary">8 Months</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Liquidity</StyledTd>
          <StyledTd variant="primary">5%</StyledTd>
          <StyledTd variant="primary">50 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd variant="primary">Unlocked</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Advisory</StyledTd>
          <StyledTd variant="primary">3%</StyledTd>
          <StyledTd variant="primary">30 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd variant="primary">24 Months</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="second">Farming Rewards</StyledTd>
          <StyledTd variant="primary">70%</StyledTd>
          <StyledTd variant="primary">700 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd variant="primary">--</StyledTd>
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd />
          <StyledTd />
          <StyledTd variant="third">Total</StyledTd>
          <StyledTd variant="third">100%</StyledTd>
          <StyledTd variant="third">1 &apos;000 &apos;000 &apos;000 SWPR</StyledTd>
          <StyledTd />
          <StyledTd />
          <StyledTd />
        </Tr>
        <Tr>
          <StyledTd width="9%" />
          <StyledTd width="9%" />
          <StyledTd width="9%" />
          <StyledTd width="9%" />
          <StyledTd width="28%" />
          <StyledTd width="18%" />
          <StyledTd width="9%" />
          <StyledTd width="9%" />
        </Tr>
      </tbody>
    </StyledTable>
  )
}

export default TokenomicsTable
