import React from 'react'
import Image from 'next/image'
import styled, { useTheme } from 'styled-components'
import { Button, Text, Link } from '@swappery/uikit'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import ConnectWalletButton from 'components/ConnectWalletButton'
import styles from './marquee.module.css'

const StyledHandSection = styled.div`
  position: relative;
  width: 100%;
  min-height: max-content;
  overflow-x: hidden;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledLeftHand = styled.div`
  z-index: 45;
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  min-height: 35px;
  width: 100px;
  min-width: 100px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 300x;
    min-width: 300px;
    height: 94px;
    min-height: 94px;
  }
`

const StyledLeftHandDiv = styled.div`
  z-index: 45;
  position: absolute;
  top: 76px;
  left: 16px;
  height: 35px;
  min-height: 35px;
  width: 48px;
  overflow: hidden;
  ${({ theme }) => theme.mediaQueries.md} {
    top: 225px;
    left: 135px;
    width: min(max(86px, calc(50% - 634px)), 290px);
    height: 94px;
    min-height: 94px;
  }
`

const StyledLeftArm = styled.div`
  z-index: 40;
  position: absolute;
  width: 32px;
  min-width: 32px;
  height: 215px;
  min-height: 215px;
  left: -8px;
  top: -43px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 95px;
    min-width: 95px;
    height: 450px;
    min-height: 450px;
    top: -127px;
    left: 41px;
  }
`

const StyledRightHand1 = styled.div`
  z-index: 40;
  position: absolute;
  width: 376px;
  min-width: 376px;
  height: 70px;
  min-height: 70px;
  left: 215px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 1240px;
    min-width: 1240px;
    left: max(calc(50% - 152px), 568px);
  }
`

const StyledRightHand2 = styled.div`
  z-index: 50;
  margin: 48px 0 0 40px;
  width: 111px;
  min-width: 111px;
  height: 112px;
  min-height: 112px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 170px 52px 0 100px;
    width: 376px;
    min-width: 376px;
  }
`

const StyledRightHandBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  width: 200px;
  min-width: 200px;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: max(calc(50% - 602px), 118px);
    width: 470px;
    min-width: 470px;
  }
`

const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  font-family: 'Orator-Std';
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding: 40px 0px 20px 0px;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 70px;
    line-height: 84px;
    padding: 150px 0px 20px 0px;
  }
`

const StyledIntro = styled.div`
  color: ${({ theme }) => theme.colors.text};

  font-family: 'Gotham';
  font-weight: 300;
  font-size: 20px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 10px 30px 10px 30px;
  }
`

const StyledButtonContainer = styled(Flex)`
  padding: 10px 30px;
  gap: 8px;
  justify-content: start;
`

const StyledIntro2 = styled.div`
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 30px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 10px 100px;
    font-size: 57px;
  }
`

const StyledIntro3 = styled(StyledIntro)`
  text-align: center;
  padding: 10px 50px;
  font-size: 18px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 10px 200px;
    font-size: 20px;
  }
`

const StyledText2 = styled.div`
  padding: 20px 30px 20px 30px;
  background: ${({ theme }) => theme.colors.cyan};
  font-weight: 500;
  font-size: 30px;
  line-height: 57.6px;
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 48px;
  }
`

const CustomHeader = styled.div`
  position: relative;
  min-height: 200px;
  background: ${({ theme }) => theme.colors.background};
`

function HandSection() {
  const { isDark } = useTheme()
  const isInitialized = useSelector<AppState, AppState['profile']['isInitialized']>(
    (state) => state.profile.isInitialized,
  )

  return (
    <StyledHandSection>
      <Flex>
        <StyledLeftArm>
          <Image src="/images/hands/left-arm.svg" alt="Left" width="95" height="450" />
        </StyledLeftArm>
        <StyledLeftHandDiv>
          <StyledLeftHand>
            <Image src="images/hands/left.svg" alt="Left" width="300" height="94" />
          </StyledLeftHand>
        </StyledLeftHandDiv>
        <Box>
          <StyledText>
            TIME TO GET
            <br />
            SWAPPY!
          </StyledText>
          <StyledIntro>Swap, trade, and earn crypto on the coolest decentralized swappery in town.</StyledIntro>
          <StyledButtonContainer>
            {isInitialized ? null : <ConnectWalletButton />}
            <Button variant="secondary" as={Link} href="/swap" style={{ borderColor: 'border' }}>
              Trade
            </Button>
          </StyledButtonContainer>
        </Box>
        <StyledRightHandBox>
          <StyledRightHand1>
            <Image src="/images/hands/right.svg" width="1240" height="235" alt="Right" />
          </StyledRightHand1>
          <StyledRightHand2>
            <Image
              src={isDark ? '/images/hands/right-arrow-dark.svg' : '/images/hands/right-arrow.svg'}
              width="376"
              height="372"
              alt="Right"
            />
          </StyledRightHand2>
        </StyledRightHandBox>
      </Flex>
      <StyledIntro2>
        THE FIRST AND ONLY CROSS-CHAIN
        <br /> DEX ON CASPER NETWORK & BSC!
      </StyledIntro2>
      <StyledIntro3>
        At The Swappery, we are building a MultiChain Dex, starting with BSC and Casper –<br /> and then moving to
        Fantom, Terra, ETH, and others.
      </StyledIntro3>
      <CustomHeader>
        <div className={styles.marquee} style={{ bottom: '-92px' }}>
          <Text color="text" fontWeight="900" fontSize="200px">
            THE SWAPPERY
          </Text>
        </div>
      </CustomHeader>

      <StyledText2>
        Exchange Tokens
        <br /> Create Liquidity Pairs
        <br /> Farm Tokens
      </StyledText2>
    </StyledHandSection>
  )
}

export default React.memo(HandSection)
